import React from "react";

function ServicePortfolio() {
  const pdfUrl = process.env.PUBLIC_URL + "/portfolio.pdf";

  const handlePdfClick = () => {
    window.open(pdfUrl, "_blank");
  };

  return (
    <div className="flex flex-col items-center justify-center py-4 mt-4 lg:py-10 lg:mt-10 px-10">
      <h1 className="text-2xl lg:text-3xl font-semibold mb-2 text-orange-500">
        Welcome to Our Portfolio
      </h1>
      <p className="text-white text-4xl lg:text-5xl font-bold mb-4">
        Click below to explore our work
      </p>
      <button
        className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded cursor-pointer"
        onClick={handlePdfClick}
      >
        Open PDF
      </button>
    </div>
  );
}

export default ServicePortfolio;
