import React from "react";
import SecurityBanner from "../components/Security/securityBanner";
import SecurityMain from "../components/Security/securityMain";

const Security = () => {
  return (
    <div className="w-full bg-gray-900">
        <SecurityBanner />
        <SecurityMain />
    </div>
  );
};

export default Security;
