import React from "react";
import Banner from "../../assets/images/3d-render-concept-phone-receiver-3d-art-design-illustration.jpg";

const ContactBanner = () => {
  return (
    <div className="relative h-96 overflow-hidden">
      <div
        className="absolute inset-x-0 bottom-0 mt-20"
        style={{
          backgroundImage: `url(${Banner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          opacity: 0.8,
          height: "210%",
        }}
      ></div>
      <div className="h-96 flex flex-col justify-center items-center absolute inset-x-0 bottom-0">
        <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-white mb-4">
          Contact Us
        </h1>
        <h2 className="text-lg sm:text-2xl md:text-3xl font-semibold text-white">
          Get In Touch
        </h2>
      </div>
    </div>
  );
};

export default ContactBanner;
