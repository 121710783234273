import React from "react";
import Banner from "../../assets/images/pattern/geometric.png";

const HomeBanner = () => {
  return (
    <div className="pt-10 lg:pt-20 sm:pt-40 lg:pb-20">
      <div className="container mx-auto flex flex-col lg:flex-row items-center">
        <div className="w-full lg:w-1/2 text-center">
          <h1 className="text-3xl lg:text-4xl font-bold mb-4 text-white">
            Welcome to CODESIGN
          </h1>
          <p className="text-md lg:text-lg mb-8 text-white">
            Your one-stop destination for digital solutions and services.
          </p>
          <a
            href="/services"
            className="bg-orange-500 text-blue-900 hover:bg-orange-400 text-md lg:text-lg font-semibold px-6 py-3 rounded-full transition duration-300"
          >
            Explore Our Services
          </a>
        </div>

        <div className="w-full lg:w-3/4 xl:w-1/2 md:w-3/4 sm:text-center bg-center bg-cover py-16 sm:py-10 max-w-full overflow-hidden flex justify-center items-center">
          <img
            src={Banner}
            className="animate-spin-slow"
            alt="Slowly Rotating Image"
          />
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;

// import React, { useRef, useEffect, useState } from "react";
// import Banner from "../../assets/images/pattern/geometric.png";

// const HomeBanner = () => {
//   return (
//     <div className="pt-10 lg:pt-20 sm:pt-40 lg:pb-20">
//       <div className="container mx-auto flex flex-col lg:flex-row items-center">
//         <div className="w-full lg:w-1/2 text-center ">
//           <h1 className="text-3xl lg:text-4xl font-bold mb-4 text-white">
//             Welcome to CODESIGN
//           </h1>
//           <p className="text-md lg:text-lg mb-8 text-white">
//             Your one-stop destination for digital solutions and services.
//           </p>
//           <a
//             href="/services"
//             className="bg-orange-500 text-blue-900 hover:bg-orange-400 text-md lg:text-lg font-semibold px-6 py-3 rounded-full transition duration-300"
//           >
//             Explore Our Services
//           </a>
//         </div>

//         <div className="w-full lg:w-3/4  xl:w-1/2 md:w-3/4  sm:text-center bg-center bg-cover py-16 sm:py-10 max-w-full overflow-hidden flex justify-center items-center">
//           <img src={Banner} />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HomeBanner;

// import React, { useRef, useEffect, useState } from "react";
// import * as THREE from "three";
// import Banner from "../../assets/images/pattern/geometric1-1024x768.png";

// const RotatingImage = () => {
//   const canvasRef = useRef();
//   const [camera, setCamera] = useState(null);
//   const [renderer, setRenderer] = useState(null);

//   useEffect(() => {
//     const scene = new THREE.Scene();
//     const initialWidth = window.innerWidth / 2;
//     const initialHeight = window.innerHeight / 2;

//     const camera = new THREE.PerspectiveCamera(
//       75,
//       initialWidth / initialHeight,
//       0.1,
//       1000
//     );
//     setCamera(camera);

//     const renderer = new THREE.WebGLRenderer({
//       canvas: canvasRef.current,
//       alpha: true,
//     });
//     setRenderer(renderer);

//     renderer.setSize(initialWidth, initialHeight);

//     // const geometry = new THREE.PlaneGeometry(7.5, 6.5);
//     const geometry = new THREE.PlaneGeometry(10, 6.5);

//     const texture = new THREE.TextureLoader().load(Banner);
//     const material = new THREE.MeshBasicMaterial({
//       map: texture,
//       transparent: true,
//     });

//     const mesh = new THREE.Mesh(geometry, material);
//     scene.add(mesh);

//     camera.position.z = 5;

//     const animate = () => {
//       requestAnimationFrame(animate);

//       mesh.rotation.x += 0.005;
//       mesh.rotation.y += 0.005;

//       renderer.render(scene, camera);
//     };

//     animate();
//   }, []);

//   useEffect(() => {
//     const handleResize = () => {
//       const width = window.innerWidth / 2;
//       const height = window.innerHeight / 2;

//       camera.aspect = width / height;
//       camera.updateProjectionMatrix();
//       renderer.setSize(width, height);
//     };

//     window.addEventListener("resize", handleResize);
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, [camera, renderer]);

//   return <canvas ref={canvasRef} className="max-w-full h-auto md:w-1/2" />;
// };

// const HomeBanner = () => {
//   return (
//     <div className="pt-10 lg:pt-20 sm:pt-40 lg:pb-20">
//       <div className="container mx-auto flex flex-col lg:flex-row items-center">
//         <div className="w-full lg:w-1/2 text-center ">
//           <h1 className="text-3xl lg:text-4xl font-bold mb-4 text-white">
//             Welcome to CODESIGN
//           </h1>
//           <p className="text-md lg:text-lg mb-8 text-white">
//             Your one-stop destination for digital solutions and services.
//           </p>
//           <a
//             href="/services"
//             className="bg-orange-500 text-blue-900 hover:bg-orange-400 text-md lg:text-lg font-semibold px-6 py-3 rounded-full transition duration-300"
//           >
//             Explore Our Services
//           </a>
//         </div>

//         <div className="w-full lg:w-3/4  xl:w-1/2 md:w-3/4  sm:text-center bg-center bg-cover py-16 sm:py-10 max-w-full overflow-hidden flex justify-center items-center">
//           <RotatingImage />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HomeBanner;
