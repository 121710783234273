import React from "react";
import Banner from "../../assets/images/business-people-partnership-support-team-urban-scene-concept.jpg";

const AboutBanner = () => {
  return (
    <div className="relative  h-96 overflow-hidden">
      <img
        src={Banner}
        alt="Service Banner"
        className="object-cover w-full h-full"
        style={{
          opacity: 0.8,
        }}
      />
      <div className="h-96 flex flex-col justify-center items-center absolute inset-x-0 bottom-0">
        <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-white mb-4">
          About Us
        </h1>
        <h2 className="text-lg sm:text-2xl md:text-3xl font-semibold text-white">
          Our Company
        </h2>
      </div>
    </div>
  );
};

export default AboutBanner;
