import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarker,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

function ContactForm() {
  const [apiResponse, setApiResponse] = useState(null);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    console.log(formData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        process.env.REACT_APP_ContactAPI_URL,
        formData
      );

      const message = response.data.message || "No message available";
      setApiResponse(message);

      setFormData({
        name: "",
        email: "",
        message: "",
      });
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message || "Request failed with an error"
        : "Request failed with an error";
      setError(errorMessage);
      //setApiResponse(errorMessage);
    }
  };

  useEffect(() => {
    if (apiResponse || error) {
      const timer = setTimeout(() => {
        setApiResponse(null);
        setError(null);
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [apiResponse, error]);

  return (
    <div className="container mx-auto mt-5 sm:flex">
      <div className="w-full sm:w-1/2 p-4">
        <p className="text-lg font-semibold mb-4 text-white text-gray-400">
          We are Professionals
        </p>
        <p className="text-white mb-6 text-4xl">
          Let's have a chat about the{" "}
          <span className="text-orange-500">project</span> or maybe even geek
          out about <span className="text-orange-500">design</span>, shoot us a
          message and let's get there.
        </p>

        <div className="mb-4">
          <p className="text-orange-500 font-medium">
            <FontAwesomeIcon icon={faMapMarker} /> <strong>Address:</strong>
          </p>
          <p className="text-gray-400">
          71-75 Shelton Street Covent Garden 
            <br />
            London WC2H 9JQ UNITED KINGDOM
          </p>
        </div>
        <div className="mb-4">
          <p className="text-orange-500 font-medium">
            <FontAwesomeIcon icon={faEnvelope} /> <strong>Email:</strong>
          </p>
          <p className="text-gray-400">info@codesignservices.com</p>
        </div>
        <div className="mb-2">
          <p className="text-orange-500 font-medium">
            <FontAwesomeIcon icon={faPhone} /> <strong>Phone:</strong>
          </p>
          <p className="text-gray-400">+447532752209</p>
        </div>
      </div>
      <div className="w-full sm:w-1/2 p-4">
        <h2 className="text-2xl font-bold mb-4 text-orange-500">Contact Us</h2>
        <form onSubmit={handleSubmit} className="max-w-md">
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block text-gray-400 font-bold mb-2"
            >
              Your Name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full border rounded-lg py-2 px-3 text-gray-700 focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-gray-400 font-bold mb-2"
            >
              Email Address
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full border rounded-lg py-2 px-3 text-gray-700 focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="message"
              className="block text-gray-400 font-bold mb-2"
            >
              Your Message
            </label>
            <textarea
              name="message"
              id="message"
              value={formData.message}
              onChange={handleChange}
              className="w-full border rounded-lg py-2 px-3 text-gray-400 focus:outline-none focus:border-blue-500 h-32"
              required
            ></textarea>
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline-blue"
            >
              Send Message
            </button>
          </div>

          {apiResponse !== null && (
            <div className="bg-green-200 text-green-800 p-2 mt-4 rounded">
              {apiResponse}
            </div>
          )}
          {error ? (
            <div className="bg-red-200 text-red-800 p-2 mt-4 rounded">
              {error}
            </div>
          ) : null}
        </form>
      </div>
    </div>
  );
}

export default ContactForm;

// import React, { useState, useEffect } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faMapMarker,
//   faEnvelope,
//   faPhone,
// } from "@fortawesome/free-solid-svg-icons";
// import axios from "axios";

// function ContactForm() {
//   const [apiResponse, setApiResponse] = useState(null);
//   const [error, setError] = useState(null);
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     message: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//     console.log(formData);
//   };

//   // const handleSubmit = async (e) => {
//   //   e.preventDefault();

//   //   try {
//   //     const response = await axios.post(
//   //       process.env.REACT_APP_ContactAPI_URL,
//   //       formData
//   //     );

//   //     setApiResponse(response.data);

//   //     setFormData({
//   //       name: "",
//   //       email: "",
//   //       message: "",
//   //     });
//   //   } catch (error) {
//   //     setError(error);
//   //   }
//   // };
//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const response = await axios.post(
//         process.env.REACT_APP_ContactAPI_URL,
//         formData
//       );

//       const message = response.data.message || "No message available";
//       setApiResponse(message);

//       setFormData({
//         name: "",
//         email: "",
//         message: "",
//       });
//     } catch (error) {
//       setError(error.message);
//     }
//   };

//   useEffect(() => {
//     if (apiResponse || error) {
//       const timer = setTimeout(() => {
//         setApiResponse(null);
//         setError(null);
//       }, 4000);

//       return () => clearTimeout(timer);
//     }
//   }, [apiResponse, error]);

//   return (
//     <div className="container mx-auto mt-5 sm:flex">
//       <div className="w-full sm:w-1/2 p-4">
//         <p className="text-lg font-semibold mb-4 text-white text-gray-400">
//           We are Professionals
//         </p>
//         <p className="text-white mb-6 text-4xl">
//           Let's have a chat about the{" "}
//           <span className="text-orange-500">project</span> or maybe even geek
//           out about <span className="text-orange-500">design</span>, shoot us a
//           message and let's get there.
//         </p>

//         <div className="mb-4">
//           <p className="text-orange-500 font-medium">
//             <FontAwesomeIcon icon={faMapMarker} /> <strong>Address:</strong>
//           </p>
//           <p className="text-gray-400">
//             212 N. 2nd St. STE 100, Richmond, KY 40475
//             <br />
//             Frankfort, Kentucky, USA
//           </p>
//         </div>
//         <div className="mb-4">
//           <p className="text-orange-500 font-medium">
//             <FontAwesomeIcon icon={faEnvelope} /> <strong>Email:</strong>
//           </p>
//           <p className="text-gray-400">info@codesignservices.com</p>
//         </div>
//         <div className="mb-2">
//           <p className="text-orange-500 font-medium">
//             <FontAwesomeIcon icon={faPhone} /> <strong>Phone:</strong>
//           </p>
//           <p className="text-gray-400">+92 311 1269529</p>
//         </div>
//       </div>
//       <div className="w-full sm:w-1/2 p-4">
//         <h2 className="text-2xl font-bold mb-4 text-orange-500">Contact Us</h2>
//         <form onSubmit={handleSubmit} className="max-w-md">
//           <div className="mb-4">
//             <label
//               htmlFor="name"
//               className="block text-gray-400 font-bold mb-2"
//             >
//               Your Name
//             </label>
//             <input
//               type="text"
//               name="name"
//               id="name"
//               value={formData.name}
//               onChange={handleChange}
//               className="w-full border rounded-lg py-2 px-3 text-gray-700 focus:outline-none focus:border-blue-500"
//               required
//             />
//           </div>
//           <div className="mb-4">
//             <label
//               htmlFor="email"
//               className="block text-gray-400 font-bold mb-2"
//             >
//               Email Address
//             </label>
//             <input
//               type="email"
//               name="email"
//               id="email"
//               value={formData.email}
//               onChange={handleChange}
//               className="w-full border rounded-lg py-2 px-3 text-gray-700 focus:outline-none focus:border-blue-500"
//               required
//             />
//           </div>
//           <div className="mb-4">
//             <label
//               htmlFor="message"
//               className="block text-gray-400 font-bold mb-2"
//             >
//               Your Message
//             </label>
//             <textarea
//               name="message"
//               id="message"
//               value={formData.message}
//               onChange={handleChange}
//               className="w-full border rounded-lg py-2 px-3 text-gray-400 focus:outline-none focus:border-blue-500 h-32"
//               required
//             ></textarea>
//           </div>
//           <div className="text-center">
//             <button
//               type="submit"
//               className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline-blue"
//             >
//               Send Message
//             </button>
//           </div>

//           {apiResponse !== null && (
//             <div
//               className={`bg-${
//                 apiResponse.includes("success") ? "green" : "red"
//               }-200 text-${
//                 apiResponse.includes("success") ? "green" : "red"
//               }-800 p-2 mt-4 rounded`}
//             >
//               {apiResponse}
//             </div>
//           )}
//           {error ? (
//             <div className="bg-red-200 text-red-800 p-2 mt-4 rounded">
//               {error}
//             </div>
//           ) : null}
//         </form>
//       </div>
//     </div>
//   );
// }

// export default ContactForm;

// import React, { useState, useEffect } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faMapMarker,
//   faEnvelope,
//   faPhone,
// } from "@fortawesome/free-solid-svg-icons";
// import axios from "axios";

// function ContactForm() {
//   const [apiResponse, setApiResponse] = useState(null);
//   const [error, setError] = useState(null);
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     message: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//     console.log(formData);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const response = await axios.post(
//         process.env.REACT_APP_ContactAPI_URL,
//         formData
//       );

//       setApiResponse(response.data);

//       setFormData({
//         name: "",
//         email: "",
//         message: "",
//       });
//     } catch (error) {
//       setError(error);
//     }
//   };

//   useEffect(() => {
//     if (apiResponse) {
//       const timer = setTimeout(() => {
//         setApiResponse(null);
//       }, 4000);

//       return () => clearTimeout(timer);
//     }
//   }, [apiResponse]);

//   return (
//     <div className="container mx-auto mt-5 sm:flex">
//       <div className="w-full sm:w-1/2 p-4">
//         <p className="text-lg font-semibold mb-4 text-white text-gray-400">
//           We are Professionals
//         </p>
//         <p className="text-white mb-6 text-4xl">
//           Let's have a chat about the{" "}
//           <span className="text-orange-500">project</span> or maybe even geek
//           out about <span className="text-orange-500">design</span>, shoot us a
//           message and let's get there.
//         </p>

//         <div className="mb-4">
//           <p className="text-orange-500 font-medium">
//             <FontAwesomeIcon icon={faMapMarker} /> <strong>Address:</strong>
//           </p>
//           <p className="text-gray-400">
//             212 N. 2nd St. STE 100, Richmond, KY 40475
//             <br />
//             Frankfort, Kentucky, USA
//           </p>
//         </div>
//         <div className="mb-4">
//           <p className="text-orange-500 font-medium">
//             <FontAwesomeIcon icon={faEnvelope} /> <strong>Email:</strong>
//           </p>
//           <p className="text-gray-400">info@codesignservices.com</p>
//         </div>
//         <div className="mb-2">
//           <p className="text-orange-500 font-medium">
//             <FontAwesomeIcon icon={faPhone} /> <strong>Phone:</strong>
//           </p>
//           <p className="text-gray-400">+92 311 1269529</p>
//         </div>
//       </div>
//       <div className="w-full sm:w-1/2 p-4">
//         <h2 className="text-2xl font-bold mb-4 text-orange-500">Contact Us</h2>
//         <form onSubmit={handleSubmit} className="max-w-md">
//           <div className="mb-4">
//             <label
//               htmlFor="name"
//               className="block text-gray-400 font-bold mb-2"
//             >
//               Your Name
//             </label>
//             <input
//               type="text"
//               name="name"
//               id="name"
//               value={formData.name}
//               onChange={handleChange}
//               className="w-full border rounded-lg py-2 px-3 text-gray-700 focus:outline-none focus:border-blue-500"
//               required
//             />
//           </div>
//           <div className="mb-4">
//             <label
//               htmlFor="email"
//               className="block text-gray-400 font-bold mb-2"
//             >
//               Email Address
//             </label>
//             <input
//               type="email"
//               name="email"
//               id="email"
//               value={formData.email}
//               onChange={handleChange}
//               className="w-full border rounded-lg py-2 px-3 text-gray-700 focus:outline-none focus:border-blue-500"
//               required
//             />
//           </div>
//           <div className="mb-4">
//             <label
//               htmlFor="message"
//               className="block text-gray-400 font-bold mb-2"
//             >
//               Your Message
//             </label>
//             <textarea
//               name="message"
//               id="message"
//               value={formData.message}
//               onChange={handleChange}
//               className="w-full border rounded-lg py-2 px-3 text-gray-400 focus:outline-none focus:border-blue-500 h-32"
//               required
//             ></textarea>
//           </div>
//           <div className="text-center">
//             <button
//               type="submit"
//               className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline-blue"
//             >
//               Send Message
//             </button>
//           </div>

//           {apiResponse ? (
//             <div className="bg-green-200 text-green-800 p-2 mt-4 rounded">
//               {apiResponse.message}
//             </div>
//           ) : null}
//           {error ? (
//             <div className="bg-red-200 text-red-800 p-2 mt-4 rounded">
//               {apiResponse.message}
//             </div>
//           ) : null}
//         </form>
//       </div>
//     </div>
//   );
// }

// export default ContactForm;

// import React, { useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faMapMarker,
//   faEnvelope,
//   faPhone,
// } from "@fortawesome/free-solid-svg-icons";
// import axios from "axios";

// function ContactForm() {
//   const [apiResponse, setApiResponse] = useState(null);
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     message: "",
//   });
//   const [error, setError] = useState(null);

//   // const handleChange = (e) => {
//   //   const { name, value } = e.target;
//   //   setFormData({
//   //     ...formData,
//   //     [name]: value,
//   //   });
//   // };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//     console.log(formData);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const response = await axios.post(
//         process.env.REACT_APP_ContactAPI_URL,
//         formData
//       );

//       setApiResponse(response.data);

//       // Optionally, you can clear the form data after a successful submission
//       setFormData({
//         name: "",
//         email: "",
//         message: "",
//       });
//     } catch (error) {
//       setError(error);
//     }
//   };

//   return (
//     <div className="container mx-auto mt-5 sm:flex">
//       <div className="w-full sm:w-1/2 p-4">
//         <p className="text-lg font-semibold mb-4 text-white text-gray-400">
//           We are Professionals
//         </p>
//         <p className="text-white mb-6 text-4xl">
//           Let's have a chat about the{" "}
//           <span className="text-orange-500">project</span> or maybe even geek
//           out about <span className="text-orange-500">design</span>, shoot us a
//           message and let's get there.
//         </p>

//         <div className="mb-4">
//           <p className="text-orange-500 font-medium">
//             <FontAwesomeIcon icon={faMapMarker} /> <strong>Address:</strong>
//           </p>
//           <p className="text-gray-400">
//             212 N. 2nd St. STE 100, Richmond, KY 40475
//             <br />
//             Frankfort, Kentucky, USA
//           </p>
//         </div>
//         <div className="mb-4">
//           <p className="text-orange-500 font-medium">
//             <FontAwesomeIcon icon={faEnvelope} /> <strong>Email:</strong>
//           </p>
//           <p className="text-gray-400">info@codesignservices.com</p>
//         </div>
//         <div className="mb-2">
//           <p className="text-orange-500 font-medium">
//             <FontAwesomeIcon icon={faPhone} /> <strong>Phone:</strong>
//           </p>
//           <p className="text-gray-400">+92 311 1269529</p>
//         </div>
//       </div>
//       <div className="w-full sm:w-1/2 p-4">
//         <h2 className="text-2xl font-bold mb-4 text-orange-500">Contact Us</h2>
//         <form onSubmit={handleSubmit} className="max-w-md">
//           <div className="mb-4">
//             <label
//               htmlFor="name"
//               className="block text-gray-400 font-bold mb-2"
//             >
//               Your Name
//             </label>
//             <input
//               type="text"
//               name="name"
//               id="name"
//               value={formData.name}
//               onChange={handleChange}
//               className="w-full border rounded-lg py-2 px-3 text-gray-700 focus:outline-none focus:border-blue-500"
//               required
//             />
//           </div>
//           <div className="mb-4">
//             <label
//               htmlFor="email"
//               className="block text-gray-400 font-bold mb-2"
//             >
//               Email Address
//             </label>
//             <input
//               type="email"
//               name="email"
//               id="email"
//               value={formData.email}
//               onChange={handleChange}
//               className="w-full border rounded-lg py-2 px-3 text-gray-700 focus:outline-none focus:border-blue-500"
//               required
//             />
//           </div>
//           <div className="mb-4">
//             <label
//               htmlFor="message"
//               className="block text-gray-400 font-bold mb-2"
//             >
//               Your Message
//             </label>
//             <textarea
//               name="message"
//               id="message"
//               value={formData.message}
//               onChange={handleChange}
//               className="w-full border rounded-lg py-2 px-3 text-gray-400 focus:outline-none focus:border-blue-500 h-32"
//               required
//             ></textarea>
//           </div>
//           <div className="text-center">
//             <button
//               type="submit"
//               className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline-blue"
//             >
//               Let's Talk
//             </button>
//           </div>
//           {apiResponse ? (
//             <div className="text-green-500">
//               Response from the API: {apiResponse.message}
//             </div>
//           ) : null}
//           {error ? (
//             <div className="text-red-500">
//               Error sending message: {apiResponse.message}
//             </div>
//           ) : null}
//         </form>
//       </div>
//     </div>
//   );
// }

// export default ContactForm;

// import React, { useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faMapMarker,
//   faEnvelope,
//   faPhone,
// } from "@fortawesome/free-solid-svg-icons";
// import axios from "axios";

// function ContactForm() {
//   const [apiResponse, setApiResponse] = useState(null);
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     message: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const response = await axios.post(
//         process.env.REACT_APP_ContactAPI_URL,
//         formData
//       );

//       setApiResponse(response.data);

//       setFormData({
//         name: "",
//         email: "",
//         message: "",
//       });
//     } catch (error) {
//       console.error("Error sending message:", error);
//     }
//   };

//   return (
//     <div className="container mx-auto mt-5 sm:flex">
//       <div className="w-full sm:w-1/2 p-4">
//         <p className="text-lg font-semibold mb-4 text-white text-gray-400">
//           We are Professionals
//         </p>
//         <p className="text-white mb-6 text-4xl">
//           Let's have a chat about the{" "}
//           <span className="text-orange-500">project</span> or maybe even geek
//           out about <span className="text-orange-500">design</span>, shoot us a
//           message and let's get there.
//         </p>

//         <div className="mb-4">
//           <p className="text-orange-500 font-medium">
//             <FontAwesomeIcon icon={faMapMarker} /> <strong>Address:</strong>
//           </p>
//           <p className="text-gray-400">
//             212 N. 2nd St. STE 100, Richmond, KY 40475
//             <br />
//             Frankfort, Kentucky, USA
//           </p>
//         </div>
//         <div className="mb-4">
//           <p className="text-orange-500 font-medium">
//             <FontAwesomeIcon icon={faEnvelope} /> <strong>Email:</strong>
//           </p>
//           <p className="text-gray-400">info@codesignservices.com</p>
//         </div>
//         <div className="mb-2">
//           <p className="text-orange-500 font-medium">
//             <FontAwesomeIcon icon={faPhone} /> <strong>Phone:</strong>
//           </p>
//           <p className="text-gray-400">+92 311 1269529</p>
//         </div>
//       </div>
//       <div className="w-full sm:w-1/2 p-4">
//         <h2 className="text-2xl font-bold mb-4 text-orange-500">Contact Us</h2>
//         <form onSubmit={handleSubmit} className="max-w-md">
//           <div className="mb-4">
//             <label
//               htmlFor="name"
//               className="block text-gray-400 font-bold mb-2"
//             >
//               Your Name
//             </label>
//             <input
//               type="text"
//               name="name"
//               id="name"
//               value={formData.name}
//               onChange={handleChange}
//               className="w-full border rounded-lg py-2 px-3 text-gray-700 focus:outline-none focus:border-blue-500"
//               required
//             />
//           </div>
//           <div className="mb-4">
//             <label
//               htmlFor="email"
//               className="block text-gray-400 font-bold mb-2"
//             >
//               Email Address
//             </label>
//             <input
//               type="email"
//               name="email"
//               id="email"
//               value={formData.email}
//               onChange={handleChange}
//               className="w-full border rounded-lg py-2 px-3 text-gray-700 focus:outline-none focus:border-blue-500"
//               required
//             />
//           </div>
//           <div className="mb-4">
//             <label
//               htmlFor="message"
//               className="block text-gray-400 font-bold mb-2"
//             >
//               Your Message
//             </label>
//             <textarea
//               name="message"
//               id="message"
//               value={formData.message}
//               onChange={handleChange}
//               className="w-full border rounded-lg py-2 px-3 text-gray-400 focus:outline-none focus:border-blue-500 h-32"
//               required
//             ></textarea>
//           </div>
//           <div className="text-center">
//             <button
//               type="submit"
//               className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline-blue"
//             >
//               Send Message
//             </button>
//           </div>
//           {apiResponse ? (
//             <div className="text-green-500">
//               Response from the API: {apiResponse.message}
//             </div>
//           ) : null}
//         </form>
//       </div>
//     </div>
//   );
// }

// export default ContactForm;
