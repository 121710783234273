import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; 

const ServiceProvider = () => {
  const [services, setServices] = useState([]);
  const [showServices, setShowServices] = useState(false);
  const apiUrl = process.env.REACT_APP_Service_URL;
  const navigate = useNavigate(); 

  useEffect(() => {
    if (apiUrl) {
      axios
        .get(apiUrl)
        .then((response) => {
          setServices(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data from the API:", error);
        });
    }

    setTimeout(() => {
      setShowServices(true);
    }, 1000);
  }, [apiUrl]);

  const handleServiceClick = (service) => {
    if (service.heading === "Cyber Security") {
      navigate("/security");
    }
  };

  return (
    <section className="py-12 px-4">
      <div className="container mx-auto">
        <div className="text-center">
          <h2 className="text-2xl font-semibold mb-4 text-orange-500">
            Our Services
          </h2>
          <p className="text-white text-5xl font-bold mb-10 font-semibold">
            We provide a wide range of creative services
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {services.map((service, index) => (
            <div
              key={service.id}
              className={`p-6 border border-orange-500 rounded-lg text-center ${
                showServices
                  ? "transition-opacity duration-500 transform translate-x-0 opacity-100 hover:shadow-md"
                  : "opacity-0 translate-x-16"
              }`}
              style={{
                transitionDelay: `${index * 0.6}s`,
              }}
              onClick={() => handleServiceClick(service)}
            >
              <img
                src={`https://source.codesignservices.com${service.icon.url}`}
                alt={`Service ${index + 1}`}
                className="w-16 h-16 mx-auto mb-4"
              />
              <h3 className="text-xl font-semibold mb-2 text-white">
                {service.heading}
              </h3>
              <p className="text-gray-400">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServiceProvider;


// import React, { useState, useEffect } from "react";
// import axios from "axios";

// const ServiceProvider = () => {
//   const [services, setServices] = useState([]);
//   const [showServices, setShowServices] = useState(false);
//   const apiUrl = process.env.REACT_APP_Service_URL;
//   // const apiUrl = "https://source.codesignservices.com/services";

//   useEffect(() => {
//     if (apiUrl) {
//       axios
//         .get(apiUrl)
//         .then((response) => {
//           setServices(response.data);
//         })
//         .catch((error) => {
//           console.error("Error fetching data from the API:", error);
//         });
//     }

//     setTimeout(() => {
//       setShowServices(true);
//     }, 1000);
//   }, [apiUrl]);

//   return (
//     <section className="py-12 px-4">
//       <div className="container mx-auto">
//         <div className="text-center">
//           <h2 className="text-2xl font-semibold mb-4 text-orange-500">
//             Our Services
//           </h2>
//           <p className="text-white text-5xl font-bold mb-10 font-semibold">
//             We provide a wide range of creative services
//           </p>
//         </div>

//         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
//           {services.map((service, index) => (
//             <div
//               key={service.id}
//               className={`p-6 border border-orange-500 rounded-lg text-center ${
//                 showServices
//                   ? "transition-opacity duration-500 transform translate-x-0 opacity-100 hover:shadow-md"
//                   : "opacity-0 translate-x-16"
//               }`}
//               style={{
//                 transitionDelay: `${index * 0.6}s`,
//               }}
//             >
//               <img
//                 src={`https://source.codesignservices.com${service.icon.url}`}
//                 alt={`Service ${index + 1}`}
//                 className="w-16 h-16 mx-auto mb-4"
//               />
//               <h3 className="text-xl font-semibold mb-2 text-white">
//                 {service.heading}
//               </h3>
//               <p className="text-gray-400">{service.description}</p>
//             </div>
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default ServiceProvider;

// import React, { useState, useEffect } from "react";

// const services = [
//   {
//     title: "Digital Marketing",
//     description: "Everything you need to get the attention of your audience.",
//     icon: "fa-bullhorn",
//   },
//   {
//     title: "2D Design",
//     description: "We Develop the Visual Identity of Your Business and Company.",
//     icon: "fa-paint-brush",
//   },
//   {
//     title: "3D Design",
//     description:
//       "With captivating 3D animations, stay one step ahead of the competition.",
//     icon: "fa-cube",
//   },
//   {
//     title: "Web Development",
//     description: "Conquer the Internet with code.",
//     icon: "fa-laptop",
//   },
//   {
//     title: "Mobile App Development",
//     description: "Conquer the app store with code.",
//     icon: "fa-mobile",
//   },
//   {
//     title: "Blockchain Development",
//     description:
//       "Blockchain has the power to change everything more than the Internet.",
//     icon: "fa-chain",
//   },
//   {
//     title: "Cyber Security",
//     description:
//       "Your data is your power, it needs to be protected and guarded with care.",
//     icon: "fa-shield",
//   },
//   {
//     title: "NFT Design",
//     description: "If you don't have a digital asset make one by using NFT.",
//     icon: "fa-diamond",
//   },
//   {
//     title: "Animation",
//     description: "Animation isn't the illusion of life, it is life.",
//     icon: "fa-film",
//   },
// ];

// const ServiceProvider = () => {
//   const [showServices, setShowServices] = useState(false);

//   useEffect(() => {
//     setShowServices(true);
//   }, []);

//   return (
//     <section className="py-12 px-4">
//       <div className="container mx-auto">
//         <div className="text-center">
//           <h2 className="text-2xl font-semibold mb-4 text-orange-500">
//             Our Services
//           </h2>
//           <p className="text-white text-5xl font-bold mb-10 font-semibold">
//             We provide a wide range of creative services
//           </p>
//         </div>

//         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
//           {services.map((service, index) => (
//             <div
//               key={index}
//               className={`p-6 border border-orange-500 rounded-lg text-center ${
//                 showServices
//                   ? "transition-opacity duration-500 transform transition translate-x-0 opacity-100 hover:shadow-md"
//                   : "opacity-0 translate-x-16"
//               }`}
//               style={{
//                 transitionDelay: `${index * 0.6}s`,
//               }}
//             >
//               <i className={`fa ${service.icon} fa-3x text-orange-500 mb-4`} />
//               <h3 className="text-xl font-semibold mb-2 text-white">
//                 {service.title}
//               </h3>
//               <p className="text-gray-400">{service.description}</p>
//             </div>
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default ServiceProvider;
