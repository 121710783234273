import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Logo from "../assets/images/logo.png";

const Header = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`bg-gray-800 text-white transition-all duration-300 py-2 px-2 md:px-24 flex flex-wrap md:flex-nowrap justify-between items-center ${
        isSticky ? "bg-gray-800 text-white sticky top-0 z-50" : ""
      }`}
    >
      <div className="flex items-center">
        <img src={Logo} alt="Logo" className="h-14 w-12" />
        <span className="ml-2 text-xl font-semibold">CODESIGN</span>
      </div>

      <div className="md:hidden ml-4">
        <div
          onClick={toggleMobileMenu}
          className="text-white hover:text-orange-500 focus:outline-none"
        >
          <FontAwesomeIcon icon={faBars} className="h-6 w-6 fill-current" />
        </div>
      </div>

      {isMobileMenuOpen && (
        <div className="md:hidden mt-4">
          <nav className="flex flex-col space-y-2">
            <a href="/" className="hover:text-orange-500">
              Home
            </a>
            <div className="relative group">
              <a href="/services" className="hover:text-orange-500">
                Services
              </a>
            </div>
            {/* <a href="/pricing" className="hover:text-orange-500">
              Pricing
            </a> */}
            <a href="/about" className="hover:text-orange-500">
              About Us
            </a>
            <a href="/contact" className="hover:text-orange-500">
              Contact Us
            </a>
          </nav>
        </div>
      )}

      <nav
        className={`md:flex md:space-x-6 hidden ${
          isMobileMenuOpen ? "" : "md:block"
        }`}
      >
        <a href="/" className="hover:text-orange-500">
          Home
        </a>
        <div className="relative group">
          <a href="/services" className="hover:text-orange-500">
            Services
          </a>
        </div>
        {/* <a href="/pricing" className="hover:text-orange-500">
          Pricing
        </a> */}
        <a href="/about" className="hover:text-orange-500">
          About Us
        </a>
        <a href="/contact" className="hover:text-orange-500">
          Contact Us
        </a>
      </nav>
    </header>
  );
};

export default Header;

// import React, { useState, useEffect } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBars } from "@fortawesome/free-solid-svg-icons";
// import Logo from "../assets/images/logo.png";

// const Header = () => {
//   const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
//   const [isSticky, setIsSticky] = useState(false);

//   const toggleMobileMenu = () => {
//     setMobileMenuOpen(!isMobileMenuOpen);
//   };

//   useEffect(() => {
//     const handleScroll = () => {
//       if (window.scrollY > 0) {
//         setIsSticky(true);
//       } else {
//         setIsSticky(false);
//       }
//     };

//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   return (
//     <header
//       className={`bg-gray-800 text-white transition-all duration-300 py-2 px-2 md:px-24 flex flex-wrap md:flex-nowrap justify-between items-center ${
//         isSticky ? "bg-gray-800 text-white sticky top-0 z-50" : ""
//       }`}
//     >
//       <div className="flex items-center">
//         <img src={Logo} alt="Logo" className="h-14 w-12" />
//         <span className="ml-2 text-xl font-semibold">CODESIGN</span>
//       </div>

//       <div className="md:hidden ml-4">
//         <div
//           onClick={toggleMobileMenu}
//           className="text-white hover:text-orange-500 focus:outline-none"
//         >
//           <FontAwesomeIcon icon={faBars} className="h-6 w-6 fill-current" />
//         </div>
//       </div>

//       {isMobileMenuOpen && (
//         <div className="md:hidden mt-4">
//           <nav className="flex flex-col space-y-2">
//             <a href="/" className="hover:text-orange-500">
//               Home
//             </a>
//             <div className="relative group">
//               <a href="/services" className="hover:text-orange-500">
//                 Services
//               </a>
//             </div>
//             {/* <a href="/pricing" className="hover:text-orange-500">
//               Pricing
//             </a> */}
//             <a href="/about" className="hover:text-orange-500">
//               About Us
//             </a>
//             <a href="/contact" className="hover:text-orange-500">
//               Contact Us
//             </a>
//           </nav>
//         </div>
//       )}

//       <nav
//         className={`md:flex md:space-x-6 absolute top-16 md:top-0 right-0 md:relative ${
//           isMobileMenuOpen ? "" : "md:block"
//         }`}
//       >
//         <a href="/" className="hover:text-orange-500">
//           Home
//         </a>
//         <div className="relative group">
//           <a href="/services" className="hover:text-orange-500">
//             Services
//           </a>
//         </div>
//         {/* <a href="/pricing" className="hover:text-orange-500">
//           Pricing
//         </a> */}
//         <a href="/about" className="hover:text-orange-500">
//           About Us
//         </a>
//         <a href="/contact" className="hover:text-orange-500">
//           Contact Us
//         </a>
//       </nav>
//     </header>
//   );
// };

// export default Header;

// import React, { useState } from "react";
// import Logo from "../assets/images/logo.png";

// const Header = () => {
//   const [isDropdownOpen, setDropdownOpen] = useState(false);

//   const toggleDropdown = () => {
//     setDropdownOpen(!isDropdownOpen);
//   };

//   return (
//     <header class="bg-gray-800 text-white py-3 px-24 flex justify-between items-center">
//       <div class="flex items-center">
//         <img src={Logo} alt="Logo" class="h-14 w-12" />
//         <span class="ml-2 text-xl font-semibold">CODESIGN</span>
//       </div>
//       <nav class="hidden md:flex space-x-6">
//         <a href="/" class="hover:text-orange-500">
//           Home
//         </a>
//         <div class="relative group">
//           <a
//             href="/services"
//             class="hover:text-orange-500"
//             onMouseEnter={toggleDropdown}
//             onMouseLeave={toggleDropdown}
//           >
//             Services
//           </a>
//           {/* {isDropdownOpen && (
//             <div class="absolute left-0 mt-2 bg-white text-gray-800 border border-gray-300 rounded-lg p-2 shadow-lg">
//               <ul>
//                 <li>
//                   <a href="#" class="block py-2 px-4 hover:bg-gray-200">
//                     Digital Marketing
//                   </a>
//                 </li>
//                 <li>
//                   <a href="#" class="block py-2 px-4 hover:bg-gray-200">
//                     Graphic Design
//                   </a>
//                 </li>
//                 <li>
//                   <a href="#" class="block py-2 px-4 hover:bg-gray-200">
//                     Animation
//                   </a>
//                 </li>
//                 <li>
//                   <a href="#" class="block py-2 px-4 hover:bg-gray-200">
//                     Architectural Design
//                   </a>
//                 </li>
//                 <li>
//                   <a href="#" class="block py-2 px-4 hover:bg-gray-200">
//                     App Development
//                   </a>
//                 </li>
//                 <li>
//                   <a href="#" class="block py-2 px-4 hover:bg-gray-200">
//                     Web Development
//                   </a>
//                 </li>
//                 <li>
//                   <a href="#" class="block py-2 px-4 hover:bg-gray-200">
//                     DevOps
//                   </a>
//                 </li>
//               </ul>
//             </div>
//           )} */}
//         </div>
//         <a href="/pricing" class="hover:text-orange-500">
//           Pricing
//         </a>
//         <a href="/about" class="hover:text-orange-500">
//           About Us
//         </a>
//         <a href="/contact" class="hover:text-orange-500">
//           Contact Us
//         </a>
//       </nav>
//     </header>
//   );
// };

// export default Header;
