import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

const FloatingIconBar = () => {
  return (
    <div className="fixed left-0 top-1/2 transform -translate-y-1/2 z-50">
      <a
        href="https://www.facebook.com/codesignservices/"
        className="bg-blue-500 text-white rounded-full py-2 px-4 block text-center hover:bg-blue-700"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faFacebook} size="lg" />
      </a>
      <a
        href="https://www.instagram.com/codesign_ltd/"
        className="bg-pink-500 text-white rounded-full py-2 px-4 block text-center hover:bg-pink-700"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faInstagram} size="lg" />
      </a>
      <a
        href="https://wa.me/18592033645"
        className="bg-green-500 text-white rounded-full py-2 px-4 block text-center hover:bg-green-700"
        target="_blank"
      >
        <FontAwesomeIcon icon={faWhatsapp} size="lg" />
      </a>
      <a
        href="https://www.linkedin.com/company/codesignservices/"
        className="bg-blue-800 text-white rounded-full py-2 px-4 block text-center hover:bg-blue-900"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faLinkedin} size="lg" />
      </a>
    </div>
  );
};

export default FloatingIconBar;

// import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faFacebook,
//   faInstagram,
//   faLinkedin,
//   faWhatsapp,
// } from "@fortawesome/free-brands-svg-icons";

// const FloatingIconBar = () => {
//   return (
//     <div className="fixed left-0 top-1/2 transform -translate-y-1/2 z-50">
//       <a
//         href="https://www.facebook.com/codesignservices/"
//         className="bg-blue-500 text-white rounded-full py-2 px-4 block text-center hover:bg-blue-700"
//         target="_blank"
//         rel="noopener noreferrer"
//       >
//         <FontAwesomeIcon icon={faFacebook} size="lg" />
//       </a>
//       <a
//         href="https://www.instagram.com/codesignservices/"
//         className="bg-pink-500 text-white rounded-full py-2 px-4 block text-center hover:bg-pink-700"
//         target="_blank"
//         rel="noopener noreferrer"
//       >
//         <FontAwesomeIcon icon={faInstagram} size="lg" />
//       </a>
//       <a
//         href="https://wa.me/18592033645"
//         className="bg-green-500 text-white rounded-full py-2 px-4 block text-center hover:bg-green-700"
//         target="_blank"
//       >
//         <FontAwesomeIcon icon={faWhatsapp} size="lg" />
//       </a>
//       <a
//         href="https://www.linkedin.com/company/codesignservices/"
//         className="bg-blue-800 text-white rounded-full py-2 px-4 block text-center hover:bg-blue-900"
//         target="_blank"
//         rel="noopener noreferrer"
//       >
//         <FontAwesomeIcon icon={faLinkedin} size="lg" />
//       </a>
//     </div>
//   );
// };

// export default FloatingIconBar;

// import React from "react";

// const FloatingIconBar = () => {
//   return (
//     <div className="fixed top-1/2 right-5 transform -translate-y-1/2 z-50">
//       <a
//         href="https://www.facebook.com/codesignservices/"
//         className="bg-blue-500 text-white rounded-t-lg py-2 px-4 block text-center hover:bg-blue-700"
//         target="_blank"
//         rel="noopener noreferrer"
//       >
//         <i className="ti-facebook"></i>
//       </a>
//       <a
//         href="https://www.instagram.com/codesignservices/"
//         className="bg-pink-500 text-white py-2 px-4 block text-center hover:bg-pink-700"
//         target="_blank"
//         rel="noopener noreferrer"
//       >
//         <i className="ti-instagram"></i>
//       </a>
//       <a
//         href="https://wa.me/18592033645"
//         className="bg-green-500 text-white py-2 px-4 block text-center hover:bg-green-700"
//         target="_blank"
//       >
//         <img
//           src="./images/whatsapp (1).png"
//           alt="WhatsApp"
//           className="h-6 w-6 mx-auto"
//         />
//       </a>
//       <a
//         href="https://www.linkedin.com/company/codesignservices/"
//         className="bg-blue-800 text-white rounded-b-lg py-2 px-4 block text-center hover:bg-blue-900"
//         target="_blank"
//         rel="noopener noreferrer"
//       >
//         <i className="ti-linkedin"></i>
//       </a>
//     </div>
//   );
// };

// export default FloatingIconBar;
