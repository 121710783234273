import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faLaptop, faRocket } from "@fortawesome/free-solid-svg-icons";

const HomeIntroduction = () => {
  const contentArray = [
    {
      heading: "Who are we?",
      text: "We are a modern software agency that aspires for greatness.",
      icon: faUsers,
    },
    {
      heading: "What do we do?",
      text: "We provide Digital IT services and IT solutions to every sort of company.",
      icon: faLaptop,
    },
    {
      heading: "What's our mission?",
      text: "Provide quality and excellence to our clients.",
      icon: faRocket,
    },
  ];

  return (
    <div className="pt-10 pb-10">
      <div className="container mx-auto mt-5 text-center lg:text-left">
        <h1 className="text-5xl font-bold mb-4 text-white">
          We work with businesses and assist <br />
          them to overcome their challenges.
        </h1>
      </div>
      <div className="container mx-auto mt-5 flex flex-col lg:flex-row">
        {contentArray.map((item, index) => (
          <div key={index} className="flex-1 p-4">
            <div className="flex items-center">
              <FontAwesomeIcon
                icon={item.icon}
                className="text-3xl pr-4 text-orange-500"
              />
              <h2 className="text-3xl font-bold mb-2 text-orange-500">
                {item.heading}
              </h2>
            </div>
            <div className="flex items-center text-gray-500 text-lg">
              <p>{item.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeIntroduction;

// import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faUsers, faLaptop, faRocket } from "@fortawesome/free-solid-svg-icons";

// const HomeIntroduction = () => {
//   const contentArray = [
//     {
//       heading: "Who are we?",
//       text: "We are a modern software agency that aspires for greatness.",
//       icon: faUsers,
//     },
//     {
//       heading: "What do we do?",
//       text: "We provide Digital IT services and IT solutions to every sort of company.",
//       icon: faLaptop,
//     },
//     {
//       heading: "What's our mission?",
//       text: "Provide quality and excellence to our clients.",
//       icon: faRocket,
//     },
//   ];

//   return (
//     <div className="pt-20 pb-20">
//       <div className="container mx-auto mt-5 flex">
//         <h1 className="text-5xl font-bold mb-4 text-white">
//           We work with businesses and assist <br />
//           them to overcome their challenges.
//         </h1>
//       </div>
//       <div className="container mx-auto mt-5 flex">
//         <div className=" text-white flex mt-5">
//           {contentArray.map((item, index) => (
//             <div key={index} className="flex-1 ">
//               <div className="flex items-center ">
//                 <FontAwesomeIcon
//                   icon={item.icon}
//                   className="text-3xl pr-4 text-orange-500"
//                 />
//                 <h2 className="text-3xl font-bold mb-2 text-orange-500">
//                   {item.heading}
//                 </h2>
//               </div>
//               <div className="flex items-center text-gray-500 text-lg">
//                 <p>{item.text}</p>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HomeIntroduction;
