import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
import Home from "./pages/home";
import Services from "./pages/services";
import AboutUs from "./pages/about";
import ContactUs from "./pages/contact";
import Pricing from "./pages/pricing";
import FloatingIconBar from "./components/floatingIconBar";
import Security from "./pages/security";

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/security" element={<Security />} />
          {/* <Route path="/pricing" element={<Pricing />} /> */}
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
        </Routes>
        <FloatingIconBar />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
