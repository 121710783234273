import React from "react";
import Logo from "../assets/images/logo.png";

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-4 px-4">
      <div className="container mx-auto flex flex-col sm:flex-row justify-between items-start">
        <div className="sm:flex">
          <div className="mt-4 sm:w-1/2 sm:pr-4">
            <h4 className="text-xl text-orange-500 font-semibold">
              Quick Links
            </h4>
            <ul className="mt-2">
              <li>
                <a href="/" className="hover:text-orange-500">
                  Home
                </a>
              </li>
              <li>
                <a href="/services" className="hover:text-orange-500">
                  Services
                </a>
              </li>
              {/* <li>
                <a href="/pricing" className="hover:text-orange-500">
                  Pricing
                </a>
              </li> */}
              <li>
                <a href="/about" className="hover:text-orange-500">
                  About Us
                </a>
              </li>
              <li>
                <a href="/contact" className="hover:text-orange-500">
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
          <div className="mt-4 sm:w-1/2 sm:pl-4">
            <h4 className="text-xl font-semibold text-orange-500">
              Contact Us
            </h4>
            <p className="mt-2">Email: info@codesignservices.com</p>
            <p>+447532752209</p>
          </div>
        </div>
        <div className="flex flex-col mt-4">
          <p className="text-xl font-semibold text-orange-500">Mission</p>
          <p className="mt-2">Provide quality and excellence to our clients.</p>
        </div>
        <div className="mt-4 flex items-center">
          <img src={Logo} alt="Logo" className="h-14 w-12" />
          <span className="ml-2 text-xl font-semibold">CODESIGN</span>
        </div>
      </div>
      <div className="mt-4 text-center">
        <p className="text-sm">Copyright © 2023, CODESIGN</p>
      </div>
    </footer>
  );
};

export default Footer;
