import React from "react";
import HomeBanner from "../components/Home/banner";
import HomeIntroduction from "../components/Home/homeIntroduction";
import HomeAgency from "../components/Home/homeAgency";
import HomeTestimonial from "../components/Home/homeTestimonial";

const Home = () => {
  return (
    <div className="w-full bg-gray-900">
      <HomeBanner />
      <HomeIntroduction />
      <HomeAgency />
      <HomeTestimonial />
    </div>
  );
};

export default Home;
