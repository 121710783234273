import React from "react";
import {
  ShieldCheckIcon,
  LockClosedIcon,
  SearchCircleIcon,
  UserGroupIcon,
  SparklesIcon,
  CheckIcon,
} from "@heroicons/react/outline";

const SecurityMain = () => {
  return (
    <div className="text-white min-h-screen flex flex-col">
      <section className="py-20">
        <div className="container mx-auto px-6 md:px-12">
          <h2 className="text-4xl font-extrabold text-center text-orange-500 mb-4">
            Our Core Services For Cyber Security
          </h2>
          <p className="text-lg text-center text-gray-400 max-w-2xl mx-auto mb-12">
            We provide a comprehensive suite of services to protect your digital environment.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                Icon: ShieldCheckIcon,
                title: "Threat Detection",
                description:
                  "Proactive monitoring and early detection of potential cyber threats.",
              },
              {
                Icon: LockClosedIcon,
                title: "Data Encryption",
                description:
                  "Secure your sensitive data with state-of-the-art encryption technologies.",
              },
              {
                Icon: SearchCircleIcon,
                title: "Security Audits",
                description:
                  "Comprehensive assessments to uncover vulnerabilities in your infrastructure.",
              },
            ].map((service, index) => (
              <div
                key={index}
                className="p-8 bg-gray-800 rounded-lg text-center transform hover:-translate-y-2 hover:bg-gray-700 transition duration-300 shadow-lg hover:shadow-xl"
              >
                <service.Icon className="w-16 h-16 mx-auto text-orange-500 mb-6" />
                <h3 className="text-2xl font-bold mb-4">{service.title}</h3>
                <p className="text-gray-400">{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="py-20 bg-gray-800">
        <div className="container mx-auto px-6 md:px-12 text-center">
          <h2 className="text-4xl font-extrabold text-orange-500 mb-6">
            Why Partner With Us?
          </h2>
          <p className="text-lg text-gray-400 max-w-3xl mx-auto mb-12">
            Our team is dedicated to providing robust cybersecurity solutions. We leverage advanced tools and strategies to ensure your systems remain secure against evolving threats.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                Icon: UserGroupIcon,
                title: "Expert Team",
                description:
                  "Our specialists are industry veterans with deep knowledge of cybersecurity.",
              },
              {
                Icon: SparklesIcon,
                title: "Innovative Tools",
                description:
                  "We use cutting-edge technologies to defend against the latest cyber threats.",
              },
              {
                Icon: CheckIcon,
                title: "Proven Success",
                description:
                  "Hundreds of satisfied clients trust us to protect their digital assets.",
              },
            ].map((reason, index) => (
              <div
                key={index}
                className="p-8 bg-gray-700 rounded-lg text-center transform hover:-translate-y-2 hover:bg-gray-600 transition duration-300 shadow-lg hover:shadow-xl"
              >
                <reason.Icon className="w-16 h-16 mx-auto text-orange-500 mb-6" />
                <h3 className="text-2xl font-bold mb-4">{reason.title}</h3>
                <p className="text-gray-400">{reason.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-20">
        <div className="container mx-auto px-6 md:px-12 text-center">
          <h2 className="text-4xl font-extrabold text-orange-500 mb-6">
            Client Testimonials
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                quote:
                  "CyberSecurity Co. has been a game-changer for our business. Their proactive approach has kept us safe from numerous threats.",
                name: "John Doe, CEO",
              },
              {
                quote:
                  "The expertise and dedication of their team are unparalleled. We’ve seen a significant improvement in our security posture.",
                name: "Jane Smith, CTO",
              },
              {
                quote:
                  "Thanks to CyberSecurity Co., we can focus on our core business, knowing our digital infrastructure is secure.",
                name: "Mark Wilson, CFO",
              },
            ].map((testimonial, index) => (
              <div
                key={index}
                className="p-8 bg-gray-800 rounded-lg text-left transform hover:-translate-y-2 hover:bg-gray-700 transition duration-300 shadow-lg hover:shadow-xl"
              >
                <p className="text-gray-400 mb-4">{testimonial.quote}</p>
                <p className="font-bold text-orange-500">{testimonial.name}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-20 bg-gradient-to-r from-orange-600 to-orange-800">
        <div className="container mx-auto px-6 md:px-12 text-center">
          <h2 className="text-4xl font-extrabold mb-4 text-white">
            Ready to Secure Your Business?
          </h2>
          <p className="text-lg text-gray-200 max-w-2xl mx-auto mb-8">
            Reach out today to learn more about our comprehensive cybersecurity services.
          </p>
          <button className="bg-white text-orange-600 py-4 px-8 rounded-full text-lg font-bold hover:bg-gray-100 transition duration-300 shadow-lg hover:shadow-xl">
            Get Started
          </button>
        </div>
      </section>
    </div>
  );
};

export default SecurityMain;



// import React from "react";
// import {
//   ShieldCheckIcon,
//   LockClosedIcon,
//   SearchCircleIcon,
//   UserGroupIcon,
//   SparklesIcon,
//   CheckIcon,
// } from "@heroicons/react/outline";

// const CyberSecurityLanding = () => {
//   return (
//     <div className="bg-gray-900 text-white min-h-screen">
//       <section className="py-24">
//         <div className="container mx-auto px-6 md:px-12">
//           <h2 className="text-4xl font-bold text-center text-orange-500 mb-12">
//             Our Core Cybersecurity Services
//           </h2>
//           <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
//             {[
//               {
//                 Icon: ShieldCheckIcon,
//                 title: "Threat Detection",
//                 description:
//                   "Proactive monitoring and early detection of potential cyber threats.",
//               },
//               {
//                 Icon: LockClosedIcon,
//                 title: "Data Encryption",
//                 description:
//                   "Secure your sensitive data with state-of-the-art encryption technologies.",
//               },
//               {
//                 Icon: SearchCircleIcon,
//                 title: "Security Audits",
//                 description:
//                   "Comprehensive assessments to uncover vulnerabilities in your infrastructure.",
//               },
//             ].map((service, index) => (
//               <div
//                 key={index}
//                 className="p-8 bg-gray-800 rounded-lg text-center transform hover:-translate-y-2 hover:bg-gray-700 transition duration-300 shadow-xl hover:shadow-2xl"
//               >
//                 <service.Icon className="w-16 h-16 mx-auto text-orange-500 mb-6" />
//                 <h3 className="text-2xl font-semibold mb-4">{service.title}</h3>
//                 <p className="text-gray-300">{service.description}</p>
//               </div>
//             ))}
//           </div>
//         </div>
//       </section>

//       <section className="py-24 bg-gray-900">
//         <div className="container mx-auto px-6 md:px-12 text-center">
//           <h2 className="text-4xl font-bold text-orange-500 mb-12">
//             Why Choose Us?
//           </h2>
//           <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
//             {[
//               {
//                 Icon: UserGroupIcon,
//                 title: "Expert Team",
//                 description:
//                   "Our specialists are industry veterans with deep knowledge of cybersecurity.",
//               },
//               {
//                 Icon: SparklesIcon,
//                 title: "Innovative Tools",
//                 description:
//                   "We use cutting-edge technologies to defend against the latest cyber threats.",
//               },
//               {
//                 Icon: CheckIcon,
//                 title: "Proven Success",
//                 description:
//                   "Hundreds of satisfied clients trust us to protect their digital assets.",
//               },
//             ].map((reason, index) => (
//               <div
//                 key={index}
//                 className="p-8 bg-gray-800 rounded-lg text-center transform hover:-translate-y-2 hover:bg-gray-700 transition duration-300 shadow-xl hover:shadow-2xl"
//               >
//                 <reason.Icon className="w-16 h-16 mx-auto text-orange-500 mb-6" />
//                 <h3 className="text-2xl font-semibold mb-4">{reason.title}</h3>
//                 <p className="text-gray-300">{reason.description}</p>
//               </div>
//             ))}
//           </div>
//         </div>
//       </section>

//       <section className="py-24 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900">
//         <div className="container mx-auto px-6 md:px-12 text-center">
//           <h2 className="text-4xl font-bold text-orange-500 mb-12">
//             What Our Clients Say
//           </h2>
//           <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
//             {[
//               {
//                 quote:
//                   "CyberSecurity Co. has been a game-changer for our business. Their proactive approach has kept us safe from numerous threats.",
//                 name: "John Doe, CEO",
//               },
//               {
//                 quote:
//                   "The expertise and dedication of their team are unparalleled. We’ve seen a significant improvement in our security posture.",
//                 name: "Jane Smith, CTO",
//               },
//               {
//                 quote:
//                   "Thanks to CyberSecurity Co., we can focus on our core business, knowing our digital infrastructure is secure.",
//                 name: "Mark Wilson, CFO",
//               },
//             ].map((testimonial, index) => (
//               <div
//                 key={index}
//                 className="p-8 bg-gray-800 rounded-lg text-left transform hover:-translate-y-2 hover:bg-gray-700 transition duration-300 shadow-xl hover:shadow-2xl"
//               >
//                 <p className="text-gray-300 mb-4 italic">"{testimonial.quote}"</p>
//                 <p className="font-bold text-orange-500">{testimonial.name}</p>
//               </div>
//             ))}
//           </div>
//         </div>
//       </section>

//       <section className="py-24 bg-gradient-to-r from-orange-600 to-orange-800">
//         <div className="container mx-auto px-6 md:px-12 text-center">
//           <h2 className="text-4xl font-extrabold mb-4 text-white">
//             Ready to Secure Your Business?
//           </h2>
//           <p className="text-lg text-gray-200 max-w-2xl mx-auto mb-8">
//             Reach out today to learn more about our comprehensive cybersecurity services.
//           </p>
//           <button className="bg-white text-orange-600 py-4 px-10 rounded-full text-lg font-semibold hover:bg-gray-100 transition duration-300 shadow-lg hover:shadow-xl">
//             Get Started
//           </button>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default CyberSecurityLanding;








// import React from "react";
// import { ShieldCheckIcon, LockClosedIcon, SearchCircleIcon, UserGroupIcon, SparklesIcon, CheckIcon } from "@heroicons/react/outline";

// const CyberSecurityLanding = () => {
//   return (
//     <div className="bg-gray-900 text-white min-h-screen flex flex-col">
//       <section className="py-20 ">
//         <div className="container mx-auto px-6 md:px-12">
//           <h2 className="text-4xl font-semibold text-center text-orange-500">Our Core Services For Cyber Security</h2>
//           <p className="mt-4 text-center text-lg text-gray-400 max-w-2xl mx-auto">
//             We provide a comprehensive suite of services to protect your digital environment.
//           </p>
//           <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-12">
//             <div className="p-8 bg-gray-700 rounded-lg text-center hover:bg-gray-600 transition duration-300 shadow-md hover:shadow-lg">
//               <ShieldCheckIcon className="w-16 h-16 mx-auto text-orange-500" />
//               <h3 className="text-2xl font-bold mt-6">Threat Detection</h3>
//               <p className="mt-4 text-gray-400">
//                 Proactive monitoring and early detection of potential cyber threats.
//               </p>
//             </div>
//             <div className="p-8 bg-gray-700 rounded-lg text-center hover:bg-gray-600 transition duration-300 shadow-md hover:shadow-lg">
//               <LockClosedIcon className="w-16 h-16 mx-auto text-orange-500" />
//               <h3 className="text-2xl font-bold mt-6">Data Encryption</h3>
//               <p className="mt-4 text-gray-400">
//                 Secure your sensitive data with state-of-the-art encryption technologies.
//               </p>
//             </div>
//             <div className="p-8 bg-gray-700 rounded-lg text-center hover:bg-gray-600 transition duration-300 shadow-md hover:shadow-lg">
//               <SearchCircleIcon className="w-16 h-16 mx-auto text-orange-500" />
//               <h3 className="text-2xl font-bold mt-6">Security Audits</h3>
//               <p className="mt-4 text-gray-400">
//                 Comprehensive assessments to uncover vulnerabilities in your infrastructure.
//               </p>
//             </div>
//           </div>
//         </div>
//       </section>
//       <section className="py-20">
//         <div className="container mx-auto px-6 md:px-12 text-center">
//           <h2 className="text-4xl font-semibold text-orange-500">Why Partner With Us?</h2>
//           <p className="mt-6 text-lg text-gray-400 max-w-3xl mx-auto">
//             Our team is dedicated to providing robust cybersecurity solutions. We leverage advanced tools and strategies to ensure your systems remain secure against evolving threats.
//           </p>
//           <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-12">
//             <div className="p-8 bg-gray-800 rounded-lg text-center hover:bg-gray-700 transition duration-300 shadow-md hover:shadow-lg">
//               <UserGroupIcon className="w-16 h-16 mx-auto text-orange-500" />
//               <h3 className="text-2xl font-bold mt-6">Expert Team</h3>
//               <p className="mt-4 text-gray-400">
//                 Our specialists are industry veterans with deep knowledge of cybersecurity.
//               </p>
//             </div>
//             <div className="p-8 bg-gray-800 rounded-lg text-center hover:bg-gray-700 transition duration-300 shadow-md hover:shadow-lg">
//               <SparklesIcon className="w-16 h-16 mx-auto text-orange-500" />
//               <h3 className="text-2xl font-bold mt-6">Innovative Tools</h3>
//               <p className="mt-4 text-gray-400">
//                 We use cutting-edge technologies to defend against the latest cyber threats.
//               </p>
//             </div>
//             <div className="p-8 bg-gray-800 rounded-lg text-center hover:bg-gray-700 transition duration-300 shadow-md hover:shadow-lg">
//               <CheckIcon className="w-16 h-16 mx-auto text-orange-500" />
//               <h3 className="text-2xl font-bold mt-6">Proven Success</h3>
//               <p className="mt-4 text-gray-400">
//                 Hundreds of satisfied clients trust us to protect their digital assets.
//               </p>
//             </div>
//           </div>
//         </div>
//       </section>
//       <section className="py-20 ">
//         <div className="container mx-auto px-6 md:px-12 text-center">
//           <h2 className="text-4xl font-semibold text-orange-500">Client Testimonials</h2>
//           <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-12">
//             <div className="p-8 bg-gray-700 rounded-lg text-left hover:bg-gray-600 transition duration-300 shadow-md hover:shadow-lg">
//               <p className="text-gray-400">
//                 "CyberSecurity Co. has been a game-changer for our business. Their proactive approach has kept us safe from numerous threats."
//               </p>
//               <p className="mt-4 font-bold text-orange-500">John Doe, CEO</p>
//             </div>
//             <div className="p-8 bg-gray-700 rounded-lg text-left hover:bg-gray-600 transition duration-300 shadow-md hover:shadow-lg">
//               <p className="text-gray-400">
//                 "The expertise and dedication of their team are unparalleled. We’ve seen a significant improvement in our security posture."
//               </p>
//               <p className="mt-4 font-bold text-orange-500">Jane Smith, CTO</p>
//             </div>
//             <div className="p-8 bg-gray-700 rounded-lg text-left hover:bg-gray-600 transition duration-300 shadow-md hover:shadow-lg">
//               <p className="text-gray-400">
//                 "Thanks to CyberSecurity Co., we can focus on our core business, knowing our digital infrastructure is secure."
//               </p>
//               <p className="mt-4 font-bold text-orange-500">Mark Wilson, CFO</p>
//             </div>
//           </div>
//         </div>
//       </section> 
//       <section className="py-20 bg-gradient-to-r from-orange-600 to-orange-800">
//         <div className="container mx-auto px-6 md:px-12 text-center">
//           <h2 className="text-4xl font-semibold">Ready to Secure Your Business?</h2>
//           <p className="mt-6 text-lg text-gray-200 max-w-2xl mx-auto">
//             Reach out today to learn more about our comprehensive cybersecurity services.
//           </p>
//           <button className="mt-8 bg-white text-orange-600 py-4 px-8 rounded-full text-lg font-bold hover:bg-gray-100 transition duration-300 shadow-lg hover:shadow-xl">
//             Get Started
//           </button>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default CyberSecurityLanding;



// import React from "react";

// const CyberSecurityLanding = () => {
//   return (
//     <div className="bg-gray-900 text-white min-h-screen flex flex-col">
     
//       <header
//         className="relative flex flex-col items-center justify-center h-screen bg-cover bg-center"
//         style={{ backgroundImage: "url('/path-to-your-image.jpg')" }}
//       >
//         <div className="absolute inset-0 bg-black opacity-60"></div>
//         <div className="z-10 text-center px-6 md:px-12">
//           <h1 className="text-5xl md:text-7xl font-extrabold leading-tight">
//             Secure Your Digital World
//           </h1>
//           <p className="mt-6 text-lg md:text-2xl text-gray-300">
//             We provide top-notch cybersecurity solutions to protect your data.
//           </p>
//           <button className="mt-8 bg-blue-600 text-white py-4 px-8 rounded-full text-lg hover:bg-blue-700 transition duration-300">
//             Learn More
//           </button>
//         </div>
//       </header>

    
//       <section className="py-20 bg-gray-800">
//         <div className="container mx-auto px-6 md:px-12">
//           <h2 className="text-4xl font-semibold text-center">Our Services</h2>
//           <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-12">
//             <div className="p-8 bg-gray-700 rounded-lg text-center hover:bg-gray-600 transition duration-300">
//               <div className="mb-6">
//                 <svg
//                   className="w-16 h-16 mx-auto text-blue-500"
//                   fill="none"
//                   stroke="currentColor"
//                   viewBox="0 0 24 24"
//                 >
          
//                 </svg>
//               </div>
//               <h3 className="text-2xl font-bold">Threat Detection</h3>
//               <p className="mt-4 text-gray-400">
//                 Early detection of cyber threats to protect your assets.
//               </p>
//             </div>
//             <div className="p-8 bg-gray-700 rounded-lg text-center hover:bg-gray-600 transition duration-300">
//               <div className="mb-6">
//                 <svg
//                   className="w-16 h-16 mx-auto text-blue-500"
//                   fill="none"
//                   stroke="currentColor"
//                   viewBox="0 0 24 24"
//                 >
      
//                 </svg>
//               </div>
//               <h3 className="text-2xl font-bold">Data Encryption</h3>
//               <p className="mt-4 text-gray-400">
//                 Secure your sensitive data with advanced encryption methods.
//               </p>
//             </div>
//             <div className="p-8 bg-gray-700 rounded-lg text-center hover:bg-gray-600 transition duration-300">
//               <div className="mb-6">
//                 <svg
//                   className="w-16 h-16 mx-auto text-blue-500"
//                   fill="none"
//                   stroke="currentColor"
//                   viewBox="0 0 24 24"
//                 >

//                 </svg>
//               </div>
//               <h3 className="text-2xl font-bold">Security Audits</h3>
//               <p className="mt-4 text-gray-400">
//                 Comprehensive audits to identify vulnerabilities in your system.
//               </p>
//             </div>
//           </div>
//         </div>
//       </section>


//       <section className="py-20 bg-gray-900">
//         <div className="container mx-auto px-6 md:px-12 text-center">
//           <h2 className="text-4xl font-semibold">Why Choose Us?</h2>
//           <p className="mt-6 text-lg text-gray-400 max-w-3xl mx-auto">
//             With years of experience in the industry, our team of experts
//             provides comprehensive cybersecurity services to ensure your data is
//             secure. We use the latest technologies to stay ahead of emerging
//             threats and protect your business from all angles.
//           </p>
//           <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-12">
//             <div className="p-8 bg-gray-800 rounded-lg text-center hover:bg-gray-700 transition duration-300">
//               <h3 className="text-2xl font-bold">Expert Team</h3>
//               <p className="mt-4 text-gray-400">
//                 Our team consists of certified professionals with a deep
//                 understanding of cybersecurity.
//               </p>
//             </div>
//             <div className="p-8 bg-gray-800 rounded-lg text-center hover:bg-gray-700 transition duration-300">
//               <h3 className="text-2xl font-bold">Advanced Tools</h3>
//               <p className="mt-4 text-gray-400">
//                 We utilize state-of-the-art tools and methodologies to secure
//                 your systems.
//               </p>
//             </div>
//             <div className="p-8 bg-gray-800 rounded-lg text-center hover:bg-gray-700 transition duration-300">
//               <h3 className="text-2xl font-bold">Proven Track Record</h3>
//               <p className="mt-4 text-gray-400">
//                 We have successfully protected hundreds of businesses from cyber
//                 threats.
//               </p>
//             </div>
//           </div>
//         </div>
//       </section>

//       <section className="py-20 bg-gray-800">
//         <div className="container mx-auto px-6 md:px-12 text-center">
//           <h2 className="text-4xl font-semibold">What Our Clients Say</h2>
//           <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-12">
//             <div className="p-8 bg-gray-700 rounded-lg text-left hover:bg-gray-600 transition duration-300">
//               <p className="text-gray-400">
//                 "CyberSecurity Co. has been instrumental in keeping our data
//                 secure. Their proactive approach has saved us from multiple
//                 threats."
//               </p>
//               <p className="mt-4 font-bold text-blue-500">John Doe, CEO</p>
//             </div>
//             <div className="p-8 bg-gray-700 rounded-lg text-left hover:bg-gray-600 transition duration-300">
//               <p className="text-gray-400">
//                 "The team at CyberSecurity Co. is extremely knowledgeable and
//                 responsive. We trust them with our most critical systems."
//               </p>
//               <p className="mt-4 font-bold text-blue-500">Jane Smith, CTO</p>
//             </div>
//             <div className="p-8 bg-gray-700 rounded-lg text-left hover:bg-gray-600 transition duration-300">
//               <p className="text-gray-400">
//                 "Thanks to CyberSecurity Co., we’ve been able to focus on
//                 growing our business without worrying about cyber threats."
//               </p>
//               <p className="mt-4 font-bold text-blue-500">Mark Wilson, CFO</p>
//             </div>
//           </div>
//         </div>
//       </section>

//       <section className="py-20 bg-gradient-to-r from-blue-600 to-blue-800">
//         <div className="container mx-auto px-6 md:px-12 text-center">
//           <h2 className="text-4xl font-semibold">Ready to Secure Your Business?</h2>
//           <p className="mt-6 text-lg text-gray-200 max-w-2xl mx-auto">
//             Contact us today to learn more about our cybersecurity services and how we can help protect your digital assets.
//           </p>
//           <button className="mt-8 bg-white text-blue-600 py-4 px-8 rounded-full text-lg font-bold hover:bg-gray-100 transition duration-300">
//             Get Started
//           </button>
//         </div>
//       </section>

      
//       <footer className="bg-gray-900 py-10">
//         <div className="container mx-auto px-6 md:px-12 text-center text-gray-400">
//           <p>&copy; 2024 CyberSecurity Co. All rights reserved.</p>
//           <div className="mt-4 flex justify-center space-x-4">
//             <a href="#" className="hover:text-white">Privacy Policy</a>
//             <a href="#" className="hover:text-white">Terms of Service</a>
//             <a href="#" className="hover:text-white">Contact Us</a>
//           </div>
//         </div>
//       </footer>
//     </div>
//   );
// };

// export default CyberSecurityLanding;


