import React, { useState, useEffect } from "react";

const testimonials = [
  {
    id: 1,
    name: "Paul T",
    text: "Working with CODESIGN was a game-changer for our online presence. Their web development team not only delivered a stunning website but also ensured that it was fully optimized for performance and user experience. Thanks to their expertise, we've seen a significant increase in traffic and conversions. Highly recommended!",
  },
  {
    id: 2,
    name: "Sam McCowan",
    text: "We approached CODESIGN to revamp our brand's visual identity, and we couldn't be happier with the results. Their graphic design team captured the essence of our brand perfectly, creating visuals that resonate with our audience. From logos to marketing materials, their creativity knows no bounds. They've become our go-to for all things design.",
  },
  {
    id: 3,
    name: "Mehul Patel",
    text: "The animation produced by CODESIGN exceeded our expectations in every way. Their team brought our vision to life with stunning visuals and seamless motion. The attention to detail and professionalism displayed throughout the process were truly impressive. If you're looking for top-notch animation services, look no further.",
  },
  {
    id: 4,
    name: "Gary Rakes",
    text:"Choosing CODESIGN for our architectural design needs was one of the best decisions we made. Their 3D animations not only showcased our projects in the best light but also helped us stand out in a competitive market. The level of craftsmanship and innovation they brought to the table was unparalleled. We're thrilled with the results.",
  },
  {
    id: 5,
    name: "Bradley Cooper",
    text:"We entrusted CODESIGN with the development of our mobile app, and they delivered beyond our expectations. Their team's technical expertise and attention to detail were evident throughout the process. They not only built a user-friendly app but also provided valuable insights to enhance its functionality. It's been a pleasure working with them.",
  },
  {
    id: 6,
    name: "Lyn Roddy",
    text: "Implementing DevOps practices with CODESIGN transformed our software development lifecycle. Their team seamlessly integrated development and operations, resulting in faster delivery times and improved collaboration. The expertise and dedication they brought to the table were instrumental in our success. We're grateful for their partnership.",
  },
];

const TestimonialSlider = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const visibleTestimonials = testimonials.slice(
    currentTestimonial,
    currentTestimonial + 2
  );

  return (
    <div className="container mx-auto pb-20 pt-20 px-4">
      <h1 className="text-xl font-semibold mb-4 text-orange-500 ">
        Clients testimonial
      </h1>
      <p className="text-5xl font-semibold mb-4 text-white">
        Check what's our clients say about us
      </p>
      <div className="flex flex-col md:flex-row justify-center">
        {visibleTestimonials.map((testimonial) => (
          <div key={testimonial.id} className="p-4 mx-4 my-2 md:w-1/2">
            <p className="text-gray-500 text-xl">{testimonial.text}</p>
            <h2 className="text-3xl font-semibold text-orange-500">
              {testimonial.name}
            </h2>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TestimonialSlider;

// import React, { useState, useEffect } from "react";

// const testimonials = [
//   {
//     id: 1,
//     name: "John Doe",
//     text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et mauris a massa tristique euismod in a purus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et mauris a massa tristique euismod in a purus.",
//   },
//   {
//     id: 2,
//     name: "Jane Smith",
//     text: "Aenean laoreet, ex at eleifend cursus, purus quam vulputate elit, non dictum nunc justo ut urna.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et mauris a massa tristique euismod in a purus.",
//   },
//   {
//     id: 3,
//     name: "John D",
//     text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et mauris a massa tristique euismod in a purus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et mauris a massa tristique euismod in a purus.",
//   },
//   {
//     id: 4,
//     name: "Jane S",
//     text: "Aenean laoreet, ex at eleifend cursus, purus quam vulputate elit, non dictum nunc justo ut urna.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et mauris a massa tristique euismod in a purus.",
//   },
//   {
//     id: 5,
//     name: "John Do",
//     text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et mauris a massa tristique euismod in a purus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et mauris a massa tristique euismod in a purus.",
//   },
//   {
//     id: 6,
//     name: "Jane Smi",
//     text: "Aenean laoreet, ex at eleifend cursus, purus quam vulputate elit, non dictum nunc justo ut urna.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et mauris a massa tristique euismod in a purus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et mauris a massa tristique euismod in a purus.",
//   },
// ];

// const TestimonialSlider = () => {
//   const [currentTestimonial, setCurrentTestimonial] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
//     }, 5000);

//     return () => clearInterval(interval);
//   }, []);

//   const visibleTestimonials = testimonials.slice(
//     currentTestimonial,
//     currentTestimonial + 2
//   );

//   return (
//     <div className="container mx-auto pb-20 pt-20">
//       <h1 className="text-xl font-semibold mb-4 text-orange-500">
//         Clients testimonial
//       </h1>
//       <p className="text-5xl font-semibold mb-4 text-white">
//         Check what's our clients say about us
//       </p>
//       <div className="flex justify-center">
//         {visibleTestimonials.map((testimonial) => (
//           <div key={testimonial.id} className=" p-4  mx-4">
//             <p className="text-gray-500 text-xl">{testimonial.text}</p>
//             <h2 className="text-3xl font-semibold text-orange-500">
//               {testimonial.name}
//             </h2>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default TestimonialSlider;
